.body {
	background-color: #eeeeee;
}
.ahia-login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}

.ahia-login-form-container {
	align-items: center;
	justify-content: center;
	padding: 50px;
	box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.81);
	border-radius: 5px;
	position: relative;
	z-index: 1;
	background: inherit;
	overflow: hidden;
}

.ahia-login-form-container:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgb(0, 0, 0);
	filter: blur(80px);
	margin: -20px;
}

.ahia-login-card {
	min-width: 310px;
	width: 40%;
}
.ahia-login-field {
	min-width: 300px;
	width: 100%;
	padding: 10px;
	opacity: 0.6;
	border: none;
	font-size: 20px;
}
.ahia-login-field:focus {
	outline: none !important;
}
.ahia-login-button {
	padding: 10px 16px !important;
	width: 100%;
	background-color: rgb(33, 117, 255) !important;
	border-radius: 0 !important;
	transition-duration: all 0.3s;
	border-width: 2px !important;
	border-style: solid !important;
	border-color: rgb(33, 117, 255) !important;
}
.ahia-login-button:hover {
	background-color: transparent !important;
	border-color: white !important;
	border-style: solid !important;
	border-width: 2px !important;
}
.ahia-signup-button {
	padding: 10px 16px !important;
	width: 100%;
	margin-left: 8px !important;
	background-color: #4672e3 !important;
	border-radius: 0 !important;
	transition-duration: all 0.3s;
	border-width: 2px !important;
	border-style: solid !important;
	border-color: #4672e3 !important;
}
.ahia-signup-button:hover {
	background-color: transparent !important;
	border-color: white !important;
	border-style: solid !important;
	border-width: 2px !important;
}
.ahia-login-bullet {
	display: inline-block;
	margin: 0 2px;
	transform: scale(0ahia-login-8);
}
.ahia-login-title {
	font-size: 14;
}
.ahia-login-pos {
	margin-bottom: 12;
}
