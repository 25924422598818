.body {
	background-color: #eeeeee;
}
.ahia-signup-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
}
