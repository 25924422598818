.ahia-dashboard-card-users {
	width: 400px;
	height: 220px;
	justify-content: center;
	text-align: center;
	margin-left: 30px;
	margin-top: 50px;
	align-items: center;
	display: flex;
}
.ahia-dashboard-cards {
	display: flex;
}
