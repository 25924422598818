@font-face {
	font-family: myRoboto;
	src: url(https://s3.amazonaws.com/cdn.ahia.com/fonts/Roboto-Regular.ttf);
}

html {
	font-family: myRoboto, sans-serif !important;
}

.spinner-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
